import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A, useParams } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';
import {http, axios} from '../../Service/http';

import {Card, CardHeader, CardBody, CardFooter, Divider, Input, Button, User, Link, Chip} from "@nextui-org/react";

import { HeartIcon, ChatBubbleOvalLeftIcon  } from '@heroicons/react/24/solid'

import TagComponent from '../../Components/TagComponent';
import JournalComponent from '../../Components/JournalComponent';
import CommentComponent from '../../Components/CommentComponent';

function JournalPage() {
  const { journalId } = useParams();

  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [journal, setJournal] = useState<any>([]);
  const [journalFormattedText, setJournalFormattedText] = useState<any>([]);

  const [belongsToMe, setBelongsToMe] = useState(false);
  const [isLiked, setIsLiked] = useState<boolean>();
  const [likeCount, setLikeCount] = useState<any>();
  const [commentCount, setCommentCount] = useState<any>();
  const [openComments, setOpenComments] = useState(false);
  const [comments, setComments] = useState<any>([]);
  const [newCommentText, setNewCommentText] = useState("");

  useEffect(() => {
    http.get(`/journal/${journalId}`).then((res) => {
      setJournal(res.data.journal);
      setIsLiked(res.data.journal?.is_liked);
      setLikeCount(res.data.journal?.likes_count);
      setCommentCount(res.data.journal?.comments_count);

      setJournalFormattedText(res.data.journal.text.split("\n"));

      if(res.data.journal.profile_id === AppContextState?.profile?.id){
        setBelongsToMe(true);
      }
    });
	}, [journalId, AppContextState]);

  // functions
  const like = () => {
    http.post(`/journal/${journal?.id}/like`).then((res) => {
      setIsLiked(true);
      setLikeCount(likeCount + 1);
    });
  }

  const unlike = () => {
    http.delete(`/journal/${journal?.id}/like`).then((res) => {
      setIsLiked(false);
      setLikeCount(likeCount - 1);
    });
  }

  const newComment = () => {
    http.post(`/journal/${journal?.id}/comment`, {
      text: newCommentText
    }).then((res) => {
      res.data.comment.is_liked = false;
      res.data.comment.likes_count = 0;
      res.data.comment.comments_count = 0;
      res.data.comment.profile = AppContextState?.profile;

      setCommentCount(commentCount + 1);
      setComments([res.data.comment, ...comments]);
      setNewCommentText('');

      if(!openComments){
        toggleComments();
      }
    });
  }

  const toggleComments = () => {
    if(!openComments){
      http.get(`/journal/${journal?.id}`).then((res) => {
        setComments(res.data.journal.comments);
        setOpenComments(true);
      });
    } else {
      setComments([]);
      setOpenComments(false);
    }
  }

  return (
    <div className="min-h-[calc(100vh-64px-64px)] w-full py-5">
      <Card>
        <CardHeader className='flex flex-row gap-2 items-start'>
          <div className='basis-1/3'>
            {journal?.profile?.image ? (
              <User
                name={journal?.profile?.name}
                description={(
                  <Link className='hover:cursor-pointer text-xs' onClick={() => navigate(`/profile/@${journal?.profile?.handle}`)}>
                    @{journal?.profile?.handle}
                  </Link>
                )}
                avatarProps={{
                  src: `${process.env.REACT_APP_API}${journal?.profile?.image}`,
                  size: "sm"
                }}
              />
            ) : (
              <User
                name={journal?.profile?.name}
                description={(
                  <Link className='hover:cursor-pointer text-xs' onClick={() => navigate(`/profile/@${journal?.profile?.handle}`)}>
                    @{journal?.profile?.handle}
                  </Link>
                )}
                avatarProps={{
                  src: `${process.env.REACT_APP_API_BASE}/storage/tempProfile.png`,
                  size: "sm"
                }}
              />
            )}
          </div>
          <div  className='basis-1/3'>
            
          </div>
          <div  className='basis-1/3 flex flex-row justify-end'>
            {belongsToMe ? (
              <Button onClick={() => navigate(`/journal/${journal?.id}/edit`)}>Edit</Button>
            ) : (
              <></>
            )}
          </div>
        </CardHeader>

        <Divider/>

        {/* List of profiles */}
        <CardBody className='flex flex-row flex-wrap gap-2'>

          <div className='w-full py-10'>
            <h1 className='text-center text-2xl font-bold	'>{journal?.title}</h1>
          </div>

          <div className='w-full px-5 pb-5'>
            {journalFormattedText.map((paragraph: string, index: number) => {
              return (
                <p key={index} className='pb-2'>
                  {paragraph}
                </p>
              )
            })}
          </div>

          <div>
              {journal.tags?.length > 0 ? (
                <div className='pt-2 flex flex-row flex-wrap gap-2'>
                  {journal.tags?.map((tag: any, index: number) => (
                    <div key={tag?.id}>
                      <Chip size='sm'>{tag?.name}</Chip>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>

          <Divider/>

          <div className='w-full flex flex-col gap-2'>
            <div className='flex gap-3'>
              <Button className='w-3/4' onClick={() => {toggleComments()}}>Comments ({commentCount})</Button>

              {isLiked ? (
                <Button className='w-1/4' onClick={() => {unlike()}}>
                  <HeartIcon className='w-10 text-rose-500'/>{likeCount}
                </Button>
              ) : (
                <Button className='w-1/4' onClick={() => {like()}}>
                  <HeartIcon className='w-8'/>{likeCount}
                </Button>
              )}
            </div>

            {openComments ? (
              <div>
                <div className='flex flex-row gap-3'>
                  <Input size='sm' className='w-3/4' type="text" placeholder='New comment' value={newCommentText} onChange={(event) => {
                    setNewCommentText(event.target.value);
                  }}/>
                  <Button color="primary" className='w-1/4' size="sm" onClick={() => {newComment()}}>Post</Button>
                </div>

                <div>
                  {comments?.map((comment: any, index: number) => (
                    <div key={comment?.id || index}>
                      <CommentComponent comment={comment}/>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default JournalPage;
