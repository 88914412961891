import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, Link as A } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';
import {http, axios} from '../../Service/http';

import {Card, CardHeader, CardBody, CardFooter} from "@nextui-org/react";
import {User, Divider, Avatar, Link, Image, Button } from "@nextui-org/react";

import ProfileCardComponent from '../../Components/ProfileCardComponent';

function ProfileFollowingPage() {
  const { AtHandle } = useParams();
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [isMe, setIsMe] = useState(false);
  const [profile, setProfile] = useState<any>(null);

  useEffect(() => {
    if(AtHandle){
      const handle = AtHandle?.split('@')[1];

      if(handle === AppContextState?.profile?.handle){
        setIsMe(true);
      }
  
      http.get(`/profile/${handle}`).then((res) => {
        setProfile(res.data.profile);
      });
    }
	}, [AtHandle, AppContextState.profile]);

  return (
    <div className="min-h-[calc(100vh-64px-64px)] w-full py-5">
      <Card>
        <CardHeader className='flex flex-row'>
          <div className='basis-1/3'>
            <User   
              name={AppContextState?.profile?.name}
              description={(
                <Link size="sm" className='hover:cursor-pointer' onClick={() => navigate(`/profile/@${AppContextState?.profile?.handle}`)}>
                  @{AppContextState?.profile?.handle}
                </Link>
              )}
              avatarProps={{
                src: "https://avatars.githubusercontent.com/u/30373425?v=4"
              }}
            />
          </div>
          <div className='basis-1/3 text-center text-2xl font-bold'>
              <h1>Following</h1>
          </div>
          <div className='basis-1/3'>

          </div>
        </CardHeader>

        <Divider/>

        {/* List of profiles */}
        <CardBody className='flex flex-row flex-wrap'>

          {profile?.following?.map((profile: any) => (
            <div key={profile?.id} className='p-3 md:basis-1/2 lg:basis-1/3'>
              <ProfileCardComponent profile={profile}/>
            </div>
          ))}

        </CardBody>
      </Card>
    </div>
  );
}

export default ProfileFollowingPage;
