import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../Context/AppContext';
import {http, axios} from '../Service/http';

// Next UI
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Link, Button} from "@nextui-org/react";
import {User, Divider, Avatar } from "@nextui-org/react";
import {Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, DropdownSection} from "@nextui-org/react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure} from "@nextui-org/react";
import {Textarea, Input} from "@nextui-org/react";

// Icons
import { BarsArrowDownIcon } from '@heroicons/react/24/solid'

function ProfileManagementComponent() {
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);
  const {isOpen, onOpen, onOpenChange} = useDisclosure();

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState<any>({});
  const [profiles, setProfiles] = useState([]);
  const [newProfileHandle, setNewProfileHandle] = useState('');
  const [newProfileHandleValid, setNewProfileHandleValid] = useState(false);
  const [newProfileHandleValidMessage, setNewProfileHandleValidMessage] = useState('This handle is already in use or invalid.');
  const [newProfileName, setNewProfileName] = useState('');
  const [newProfileAge, setNewProfileAge] = useState('');
  const [newProfileGender, setNewProfileGender] = useState('');
  const [newProfileOrientation, setNewProfileOrientation] = useState('');
  const [newProfileBio, setNewProfileBio] = useState('');

  useEffect(() => {
    setProfile(AppContextState.profile);
    setProfiles(AppContextState.profiles);
  }, []);

  const logout = () => {
    AppContextState.updateAccessToken(null);
    AppContextState.updateUser(null);
    AppContextState.updateProfiles(null);
    AppContextState.updateProfile(null);

    navigate('/login');
  }

  const setCurrentProfile = (profile: any) => {
    console.log(profile);
    AppContextState.updateProfile(profile);
    setProfile(profile);
  }

  const checkAndSetHandle = (handle: string) => {
    http.get(`/profile/${handle}`).then((res) => {
      if(res.status === 404){
        setNewProfileHandleValid(true);
        setNewProfileHandleValidMessage('');
      } else {
        setNewProfileHandleValid(false);
        setNewProfileHandleValidMessage('This handle is already in use or invalid.');
      }
    });
    setNewProfileHandle(handle);
  }

  const saveNewProfile = () => {
    if(newProfileHandleValid){
      http.post(`/profile`, {
        handle: newProfileHandle,
        name: newProfileName,
        age: newProfileAge,
        gender: newProfileGender,
        orientation: newProfileOrientation,
        bio: newProfileBio,
      }).then((res) => {

        AppContextState.updateProfiles([...AppContextState.profiles, res.data.profile]);
        setCurrentProfile(res.data.profile);

        // reset newProfileValues
        setNewProfileHandle('');
        setNewProfileHandleValid(false);
        setNewProfileHandleValidMessage('');
        setNewProfileName('');
        setNewProfileAge('');
        setNewProfileGender('');
        setNewProfileOrientation('');
        setNewProfileBio('');

        // close modal
        onOpenChange();
      }).catch((error) => {
        console.log(error);

        // reset newProfileValues
        setNewProfileHandle('');
        setNewProfileHandleValid(false);
        setNewProfileHandleValidMessage('');
        setNewProfileName('');
        setNewProfileAge('');
        setNewProfileGender('');
        setNewProfileOrientation('');
        setNewProfileBio('');

        // close modal
        onOpenChange();
      });
    }
  }

  const removeProfile = () => {
    http.delete(`/profile/${AppContextState.profile?.handle}`).then((res) => {
      const updatedProfilesList = AppContextState.profiles.filter((profile: any) => {
        if(AppContextState.profile?.id !== profile?.id){
          return profile;
        }
      });

      AppContextState.updateProfiles(updatedProfilesList);
      setCurrentProfile(updatedProfilesList[0]);

      localStorage.setItem('profiles', JSON.stringify(updatedProfilesList));
      localStorage.setItem('profile', JSON.stringify(updatedProfilesList[0]));
      
    }).catch((error) => {
      console.log(error);
    });
  }

  return (
    <>
      <NavbarItem className='flex justify-center items-center gap-4'>
        <User   
          name={profile?.name}
          description={(
            <Link size="sm" className='hover:cursor-pointer' onClick={() => navigate(`/profile/@${profile?.handle}`)}>
              @{profile?.handle}
            </Link>
          )}
          avatarProps={{
            src: `${process.env.REACT_APP_API}${profile?.image}`
          }}
        />

        <Dropdown className='text-white bg-slate-950'>
          <DropdownTrigger>
            <Button isIconOnly>
              <BarsArrowDownIcon className='w-5'/>
            </Button>
          </DropdownTrigger>
          <DropdownMenu aria-label="Static Actions" className='text-white bg-slate-950'>
            <DropdownSection showDivider>
            {profiles?.map((profileItem: any, index: number) => (
              profileItem?.id === profile?.id ? (
                <DropdownItem key={`profile_list-${profileItem.id}`} startContent={<Avatar isBordered color="primary" src={`${process.env.REACT_APP_API}${profileItem?.image}`} />} description={`@${profileItem?.handle}`}>{profileItem?.name}</DropdownItem>
              ) : (
                <DropdownItem key={`profile_list-${profileItem.id}`} onClick={() => setCurrentProfile(profileItem)} startContent={<Avatar src={`${process.env.REACT_APP_API}${profileItem?.image}`} />} description={`@${profileItem?.handle}`}>{profileItem?.name}</DropdownItem>
              )
            ))}
            </DropdownSection>
            <DropdownSection>
              <DropdownItem key="create" className='text-primary hover:cursor-pointer' color="primary" onPress={onOpen}>
                Create Profile
              </DropdownItem>
              <DropdownItem key="delete" className="text-danger hover:cursor-pointer" color="danger" onClick={() => removeProfile()}>
                Delete @{profile?.handle}
              </DropdownItem>
              <DropdownItem key="logout" className="text-danger hover:cursor-pointer" color="danger" onClick={() => logout()}>
                Logout
              </DropdownItem>
            </DropdownSection>
          </DropdownMenu>
        </Dropdown>
      </NavbarItem>

      {/* New profile modal */}
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} backdrop="blur">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">New Profile</ModalHeader>
              <ModalBody>
                <Input 
                  isDisabled={isLoading} 
                  type="text" 
                  label="Profile Handle" 
                  placeholder='Enter your handle' 
                  value={newProfileHandle} 
                  validationState={newProfileHandleValid ? 'valid' : 'invalid'}
                  errorMessage={newProfileHandleValidMessage}
                  startContent="@"
                  onChange={(event) => {
                    checkAndSetHandle(event.target.value);
                  }} 
                />
                <Input 
                  isDisabled={isLoading} 
                  type="text" 
                  label="Profile Name" 
                  placeholder='Enter your name'
                  value={newProfileName}
                  onChange={(event) => {
                    setNewProfileName(event.target.value);
                  }} 
                />
                <Input 
                  isDisabled={isLoading} 
                  type="number" 
                  label="Profile Age" 
                  placeholder='Enter your age'
                  value={newProfileAge}
                  onChange={(event) => {
                    setNewProfileAge(event.target.value);
                  }} 
                />
                <Input 
                  isDisabled={isLoading} 
                  type="text" 
                  label="Profile Gender" 
                  placeholder='Enter your gender'
                  value={newProfileGender}
                  onChange={(event) => {
                    setNewProfileGender(event.target.value);
                  }} 
                />
                <Input 
                  isDisabled={isLoading} 
                  type="text" 
                  label="Profile Orientation" 
                  placeholder='Enter your orientation'
                  value={newProfileOrientation}
                  onChange={(event) => {
                    setNewProfileOrientation(event.target.value);
                  }} 
                />
                <Textarea
                  label="Bio"
                  placeholder="Enter profile bio"
                  value={newProfileBio}
                  onChange={(event) => {
                    setNewProfileBio(event.target.value);
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onClick={onClose}>
                  Close
                </Button>
                <Button color="primary" onPress={() => { saveNewProfile() }}>
                  Create
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default ProfileManagementComponent;
