import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A } from 'react-router-dom';

import { AppContext } from '../Context/AppContext';
import {http, axios} from '../Service/http';

import {Card, CardHeader, CardBody, CardFooter} from "@nextui-org/react";
import {Tabs, Tab} from "@nextui-org/react";
import { Image, Divider, Button, Link } from "@nextui-org/react";
import {Avatar, AvatarGroup, Textarea} from "@nextui-org/react";
import {Chip, Input} from "@nextui-org/react";

function NewPostComponent({posts, setPosts}: any) {
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [tags, setTags] = useState([]);
  const [searchedTagString, setSearchedTagString] = useState("");
  const [searchedTags, setSearchedTags] = useState<any>([]);
  const [postTags, setPostTags] = useState<any>([]);
  const [postText, setPostText] = useState<string>("");

  useEffect(() => {
    http.get(`/tag`).then((res) => {
      setTags(res.data.tags);
    });
	}, []);

  const createPost = () => {
    http.post(`/post`, {
      text: postText,
      tags: postTags?.map((tag: any) => tag.id)
    }).then((res) => {
      setPosts([res.data.post, ...posts])
      setTags([]);
    });
  }

  const addTagToPost = (tag: any) => {
    setPostTags([tag, ...postTags]);
    setSearchedTags(searchedTags.filter((searchTag: any) => {
      if(searchTag.id !== tag.id){
        return searchTag;
      }
      return false;
    }));
  }

  const removeTagFromPost = (tag: any) => {
    setSearchedTags([tag, ...searchedTags]);
    setPostTags(postTags.filter((searchTag: any) => {
      if(searchTag.id !== tag.id){
        return searchTag;
      }
      return false;
    }));
  }

  const searchTags = (text: string) => {
    setSearchedTagString(text);
    const postTagIds: Array<any> = postTags?.map((tag: any) => tag.id);
    setSearchedTags(tags.filter((tag: any) => {
      if(text !== "" && tag?.name.includes(text) && !postTagIds.includes(tag.id)){
        return tag;
      }
      return false;
    }))
  }

  return (
    <>
      <Card>
        <CardBody className='p-2 flex flex-col gap-1'>
          <Textarea 
            minRows={1} 
            maxRows={3}
            placeholder='New Post'
            value={postText}
            onChange={(event) => {
              setPostText(event.target.value);
            }}></Textarea>
            {postText !== "" ? (
              <>
                <div className='flex flex-row gap-2'>
                  <div className='flex flex-col gap-1 basis-1/2 rounded p-1'>
                    <Input size='sm' type="text" placeholder='Tag search' value={searchedTagString} onChange={(event) => {
                      searchTags(event.target.value);
                    }}/>

                    <div className='flex flex-row flex-wrap gap-1'>
                      {searchedTags?.map((tag: any, index: number) => (
                        <div key={tag?.id}>
                          <Chip onClick={() => addTagToPost(tag)}>{tag?.name}</Chip>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='flex flex-row flex-wrap basis-1/2 gap-1 rounded p-1'>
                    {postTags?.map((tag: any, index: number) => (
                      <div key={tag?.id}>
                        <Chip onClose={() => removeTagFromPost(tag)}>{tag?.name}</Chip>
                      </div>
                    ))}
                  </div>
                </div>

                <Button size='sm' onClick={() => createPost()}>Post</Button>
              </>
            ) : (
              <></>
            )}
        </CardBody>
      </Card>
    </>
  );
}

export default NewPostComponent;
