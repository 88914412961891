import React from 'react';

export const AppContext = React.createContext({
  accessToken: null,
  updateAccessToken: (value: any) => {},
  user: null,
  updateUser: (value: any) => {},
  profiles: null,
  updateProfiles: (value: any) => {},
  profile: null,
  updateProfile: (value: any) => {},
});
