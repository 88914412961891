import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A, useParams } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';
import {http, axios} from '../../Service/http';

import {Card, CardHeader, CardBody, CardFooter, Divider, Input, Button, User, Link, Textarea} from "@nextui-org/react";

import { HeartIcon, ChatBubbleOvalLeftIcon  } from '@heroicons/react/24/solid'

import TagComponent from '../../Components/TagComponent';
import JournalComponent from '../../Components/JournalComponent';
import CommentComponent from '../../Components/CommentComponent';

function JournalCreatePage() {
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [updateTitle, setUpdateTitle] = useState("");
  const [updateText, setUpdateText] = useState("");
  const [tags, setTags] = useState<any>([]);

  useEffect(() => {
    const now = new Date();
    setUpdateTitle(`${now.toDateString()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`);
  }, []);

  const save = () => {
    http.post(`/journal`, {
      title: updateTitle,
      text: updateText,
      tags: tags?.map((tag: any) => tag.id)
    }).then((res) => {
      navigate(`/journal/${res.data.journal.id}`);
    });
  }

  return (
    <div className="min-h-[calc(100vh-64px-64px)] w-full py-5">
      <Card>
        <CardHeader className='flex flex-row gap-2 items-start'>
          <div className='basis-1/3'>
            {AppContextState?.profile?.image ? (
              <User
                name={AppContextState?.profile?.name}
                description={(
                  <Link className='hover:cursor-pointer text-xs' onClick={() => navigate(`/profile/@${AppContextState?.profile?.handle}`)}>
                    @{AppContextState?.profile?.handle}
                  </Link>
                )}
                avatarProps={{
                  src: `${process.env.REACT_APP_API}${AppContextState?.profile?.image}`,
                  size: "sm"
                }}
              />
            ) : (
              <User
                name={AppContextState?.profile?.name}
                description={(
                  <Link className='hover:cursor-pointer text-xs' onClick={() => navigate(`/profile/@${AppContextState?.profile?.handle}`)}>
                    @{AppContextState?.profile?.handle}
                  </Link>
                )}
                avatarProps={{
                  src: `${process.env.REACT_APP_API_BASE}/storage/tempProfile.png`,
                  size: "sm"
                }}
              />
            )}
          </div>
          <div  className='basis-1/3'>
            
          </div>
          <div  className='basis-1/3 flex flex-row justify-end'>
            <Button onClick={() => save()}>Save</Button>
          </div>
        </CardHeader>

        <Divider/>

        <CardBody className='flex flex-row flex-wrap gap-2'>

          <div className='w-full'>
            <Input type="text" label="Title" placeholder='Enter updated title' value={updateTitle} onChange={(event) => {
              setUpdateTitle(event.target.value);
            }} />
          </div>

          <div className='w-full'>
            <Textarea
              maxRows={100}
              placeholder='New Journal Text'
              value={updateText}
              onChange={(event) => {
                setUpdateText(event.target.value);
              }}></Textarea>
          </div>

          <Divider/>

          <div className='w-full flex flex-col gap-2'>
            <TagComponent selectedTagsInit={[]} onTagSelect={(tags: any) => {
              setTags(tags);
            }}/>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default JournalCreatePage;
