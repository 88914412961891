import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A } from 'react-router-dom';

import { AppContext } from '../Context/AppContext';
import {http, axios} from '../Service/http';

import {Chip, Input} from "@nextui-org/react";

function TagComponent({selectedTagsInit, onTagSelect}: any) {
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [tags, setTags] = useState<any>([]);
  const [tagSearch, setTagSearch] = useState("");
  const [searchedTags, setSearchedTags] = useState<any>([]);
  const [selectedTags, setSelectedTags] = useState<any>([]);

  useEffect(() => {
    http.get(`/tag`).then((res) => {
      setTags(res.data.tags);
    });
	}, []);

  useEffect(() => {
    if(selectedTags.length < 1 && selectedTagsInit){
      setSelectedTags(selectedTagsInit);
    }
	}, [selectedTagsInit]);

  const addTag = (tag: any) => {
    console.log(tag);
    console.log(selectedTags);
    const selected = [tag, ...selectedTags];

    setSearchedTags(searchedTags.filter((searchTag: any) => {
      if(searchTag.id !== tag.id){
        return searchTag;
      }
      return false;
    }));
    setSelectedTags(selected);
    onTagSelect(selected);
  }

  const removeTag = (tag: any) => {
    setSearchedTags([tag, ...searchedTags]);
    const selected = selectedTags.filter((searchTag: any) => {
      if(searchTag.id !== tag.id){
        return searchTag;
      }
      return false;
    });
    setSelectedTags(selected);
    onTagSelect(selected);
  }

  const searchTags = (text: string) => {
    setTagSearch(text);
    const tagIds: Array<any> = selectedTags?.map((tag: any) => tag.id);
    setSearchedTags(tags.filter((tag: any) => {
      if(text !== "" && tag?.name?.includes(text) && !tagIds?.includes(tag.id)){
        return tag;
      }
      return false;
    }))
  }

  return (
    <>
      <div className='flex flex-row gap-2 w-full'>
        <div className='flex flex-col gap-1 basis-1/2 rounded p-1'>
          <Input size='sm' type="text" placeholder='Tag search' value={tagSearch} onChange={(event) => {
            searchTags(event.target.value);
          }}/>

          <div className='flex flex-row flex-wrap gap-1'>
            {searchedTags?.map((tag: any, index: number) => (
              <div key={tag?.id + index}>
                <Chip onClick={() => addTag(tag)}>{tag?.name}</Chip>
              </div>
            ))}
          </div>
        </div>
        <div className='flex flex-row flex-wrap basis-1/2 gap-1 rounded p-1'>
          {selectedTags?.map((tag: any, index: number) => (
            <div key={tag?.id + index}>
              <Chip onClose={() => removeTag(tag)}>{tag?.name}</Chip>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default TagComponent;
