import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A } from 'react-router-dom';

import { AppContext } from '../Context/AppContext';
import {http, axios} from '../Service/http';

import FeedComponent from '../Components/FeedComponent';

function HomePage() {
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  useEffect(() => {
		console.log(AppContextState.accessToken);
	}, [AppContextState.accessToken]);

  return (
    <div className="HomePage min-h-[calc(100vh-64px-64px)] py-5">
      <FeedComponent/>
    </div>
  );
}

export default HomePage;
