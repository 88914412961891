import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A, useParams } from 'react-router-dom';

import { AppContext } from '../Context/AppContext';
import {http, axios} from '../Service/http';

import {Card, CardHeader, CardBody, CardFooter} from "@nextui-org/react";
import {User, Divider, Avatar, Link, Image, Button, Chip } from "@nextui-org/react";

import PostComponent from './PostComponent';

function ProfileCardComponent({profile}: any) {
  const { AtHandle } = useParams();

  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [isMe, setIsMe] = useState(false);

  useEffect(() => {
    if(profile?.handle === AppContextState?.profile?.handle){
      setIsMe(true);
    }
	}, [profile, AppContextState.profile]);

  return (
    <>
      <Card className="h-full basis-full">
        <CardHeader className="flex flex-col gap-3">
          {/* Name and handle */}
          <div className='w-full flex justify-between'>
            <div className="flex flex-col">
              <p className="text-md">{profile?.name}</p>
              <Link size="sm" className='hover:cursor-pointer' onClick={() => navigate(`/profile/@${profile?.handle}`)}>@{profile?.handle}</Link>
            </div>

            {/* Follow find */}
            <div className="flex justify-center items-center gap-3">
              {!isMe ? (
                <>
                  <Button color="primary" size="sm">Follow</Button>
                  <Button color="primary" size="sm">Friend</Button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          {/* Age gender orientation */}
          <div className='w-full flex flex-row justify-around'>
            <p className="text-small text-default-500">Age: {profile?.age}</p>
            <p className="text-small text-default-500">Gender: {profile?.gender}</p>
            <p className="text-small text-default-500">Orientation: {profile?.orientation}</p>
          </div>
        </CardHeader>

        <Divider/>

        {/* Main profile */}
        <CardBody className='flex flex-col gap-3'>
          {/* Profile image /storage/tempProfile.png */}
          {profile.image ? (
            <Image
              alt="NextUI hero Image"
              src={`${process.env.REACT_APP_API}${profile?.image}`}
              onClick={() => navigate(`/profile/@${profile?.handle}`)}
              className='hover:cursor-pointer'
            />
          ) : (
            <Image
              alt="NextUI hero Image"
              src={`${process.env.REACT_APP_API_BASE}/storage/tempProfile.png`}
              onClick={() => navigate(`/profile/@${profile?.handle}`)}
              className='hover:cursor-pointer'
            />
          )}

          {/* Friends following followers */}
          <div className='flex flex-row justify-around text-sm'>
            <Link className='hover:cursor-pointer' onClick={() => navigate(`/profile/@${profile?.handle}/friends`)}>Friends ({profile?.friends_count})</Link>
            <Link className='hover:cursor-pointer' onClick={() => navigate(`/profile/@${profile?.handle}/followers`)}>Followers ({profile?.followers_count})</Link>
            <Link className='hover:cursor-pointer' onClick={() => navigate(`/profile/@${profile?.handle}/following`)}>Following ({profile?.following_count})</Link>
          </div>

          <Divider/>

          {/* Bio */}
          <div>
            <p className=' rounded-xl p-1'>{profile?.bio}</p>
          </div>

          <div className='flex flex-row flex-wrap gap-2'>
            {profile.tags?.map((tag: any, index: number) => (
              <div key={tag?.id}>
                <Chip>{tag?.name}</Chip>
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default ProfileCardComponent;
