import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A } from 'react-router-dom';

import { AppContext } from '../Context/AppContext';
import {http, axios} from '../Service/http';

// Next UI
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Link, Button} from "@nextui-org/react";
import {User, Divider, Avatar } from "@nextui-org/react";
import {Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, DropdownSection} from "@nextui-org/react";

// Icons
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/solid'

// Components
import ProfileManagementComponent from './ProfileManagementComponent';
import NotificationComponent from './NotificationComponent';

function HeaderComponent() {
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems = [
    {
      title: 'Profiles',
      path: '/profile'
    },{
      title: 'Posts',
      path: '/post'
    },{
      title: 'Journals',
      path: '/journal'
    },{
      title: 'Roleplay',
      path: '/roleplay'
    },{
      title: 'Images',
      path: '/image'
    },{
      title: 'Comments',
      path: '/comment'
    }
  ];

  const goTo = (path: string) => {
    navigate(path);
    setIsMenuOpen(false);
  }

  useEffect(() => {
	}, []);

  return (
    <>
      <Navbar isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen} disableAnimation className='bg-slate-950 text-slate-200	'>

        {/* Left navbar content */}
        <NavbarContent>
          <NavbarMenuToggle
            className="md:hidden"
          />
          <NavbarBrand>
            <Link className="font-bold text-xl text-inherit hover:cursor-pointer" color="foreground" onPress={() => goTo('/')}>
              Ficted
            </Link>
          </NavbarBrand>
          
          {/*  Menu items */}
          <div className='flex-row gap-2 hidden md:flex'>
            {AppContextState.accessToken ? (
              <>
                {menuItems.map((item, index) => (
                  <NavbarMenuItem key={index}>
                    <Link
                      className="w-full hover:cursor-pointer"
                      size="lg"
                      onPress={() => {
                        goTo(item.path)
                      }}
                    >
                      {item.title}
                    </Link>
                  </NavbarMenuItem>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>        
        </NavbarContent>


        {/* Right navbar content */}
        <NavbarContent justify="end">
          {!AppContextState.accessToken ? (
            <>
              <NavbarItem className="hidden md:flex">
                <Link className="hover:cursor-pointer" onPress={() => goTo('/login')}>
                  login
                </Link>
              </NavbarItem>
              <NavbarItem>
                <Button onPress={() => goTo('/signup')} color="primary" href="#" variant="flat">
                  Sign Up
                </Button>
              </NavbarItem>
            </>
          ) : (
            <>
              <ProfileManagementComponent/>
              <NotificationComponent/>

              <Button isIconOnly onClick={() => navigate('/chat')}>
                <ChatBubbleLeftEllipsisIcon className='w-5'/>
              </Button>
            </>
          )}
        </NavbarContent>

        {/* Navbar mobile dropdown */}
        <NavbarMenu className='bg-slate-950/75 backdrop-blur'>
          {menuItems.map((item, index) => (
            <NavbarMenuItem key={index}>
              <Link
                className="w-full hover:cursor-pointer"
                size="lg"
                onPress={() => {
                  goTo(item.path)
                }}
              >
                {item.title}
              </Link>
            </NavbarMenuItem>
          ))}
        </NavbarMenu>
      </Navbar>
    </>
  );
}

export default HeaderComponent;
