import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { AppContext } from '../Context/AppContext';
import {http, axios} from '../Service/http';

function SignUpPage() {
  const navigate = useNavigate();
	const AppContextState = useContext(AppContext);

  const [state, setState] = useState(false);

  useEffect(() => {
    setState(true);
		console.log(`Component mounted. ${state}`);
	}, []);

  return (
    <div className="LoginPage">
      <p>Sign Up</p>
    </div>
  );
}

export default SignUpPage;
