import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A, useParams } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';
import {http, axios} from '../../Service/http';

import {Card, CardHeader, CardBody, CardFooter} from "@nextui-org/react";
import {User, Divider, Link, Image, Button } from "@nextui-org/react";
import {Tabs, Tab} from "@nextui-org/react";
import {Avatar, AvatarGroup, Textarea} from "@nextui-org/react";
import {Chip, Input} from "@nextui-org/react";

import ImageUploadComponent from '../../Components/ImageUploadComponent';
import ImageComponent from '../../Components/ImageComponent';

function ProfileImagesPage() {
  const { AtHandle } = useParams();

  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [isMe, setIsMe] = useState(false);
  const [handle, setHandle] = useState("");
  const [images, setImages] = useState([]);

  useEffect(() => {
    if(AtHandle){
      const handleText = AtHandle?.split('@')[1];
      setHandle(handleText);

      if(handleText === AppContextState?.profile?.handle){
        setIsMe(true);
      } else {
        setIsMe(false);
      }
  
      http.get(`/image`, {
        params: {
          handle: handleText
        }
      }).then((res) => {
        setImages(res.data.images);
      });
    }
  }, [AtHandle]);

  return (
    <>
      <div className="min-h-[calc(100vh-64px-64px)] w-full py-5">
        <Card>
          <CardHeader>
            <ImageUploadComponent images={images} setImages={setImages}/>
          </CardHeader>
          <Divider/>
          <CardBody className='flex flex-row flex-wrap justify-around p-2'>

            {/* Images */}
            {images?.map((image: any) => (
              <div key={image?.id} className='p-3 md:basis-1/2 lg:basis-1/3 flex flex-row flex-wrap justify-around'>
                <ImageComponent image={image} images={images} setImages={setImages}/>
              </div>
            ))}
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default ProfileImagesPage;
