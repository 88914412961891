import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';
import {http, axios} from '../../Service/http';

import {Card, CardHeader, CardBody, CardFooter, Divider, Input, Button} from "@nextui-org/react";

import TagComponent from '../../Components/TagComponent';
import JournalComponent from '../../Components/JournalComponent';

function JournalsPage() {
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [journals, setJournals] = useState<any>([]);

  const [titleSearch, setTitleSearch] = useState<any>("");
  const [textSearch, setTextSearch] = useState<any>("");
  const [tagSearch, setTagSearch] = useState<any>([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    searchJournals();
	}, []);

  const searchJournals = () => {
    http.get(`/journal`, {
      params: {
        title: titleSearch,
        text: textSearch,
        tags: tagSearch?.map((tag: any) => tag.id),
        order: "latest",
        page: 1,
        quantity: 30,
      }
    }).then((res) => {
      setJournals(res.data.journals);
    });
  }

  const loadMore = () => {
    http.get(`/journal`, {
      params: {
        title: titleSearch,
        text: textSearch,
        tags: tagSearch?.map((tag: any) => tag.id),
        order: "latest",
        page: page + 1,
        quantity: 30,
      }
    }).then((res) => {
      setJournals([...journals, ...res.data.journals]);
      setPage(page + 1);
    });
  }

  return (
    <div className="min-h-[calc(100vh-64px-64px)] w-full py-5">
      <Card>
        <CardHeader className='flex flex-row gap-2 items-start'>
          <div className='basis-1/4 flex flex-col gap-2'>
            <Input size='sm' type="text" label="Search Title" value={titleSearch} onChange={(event) => {
              setTitleSearch(event.target.value);
            }}/>
            <Input size='sm' type="text" label="Search Text" value={textSearch} onChange={(event) => {
              setTextSearch(event.target.value);
            }}/>
          </div>
          <div className='basis-2/4 flex flex-col'>
            <TagComponent selectedTagsInit={[]} onTagSelect={(tags: any) => {
              setTagSearch(tags);
            }}/>
          </div>
          <div className='basis-1/4 flex flex-col'>
            <Button color='primary' onClick={() => searchJournals()}>Search</Button>
          </div>
        </CardHeader>

        <Divider/>

        {/* List of profiles */}
        <CardBody className='flex flex-row flex-wrap'>
          {journals?.map((journal: any) => (
            <div key={journal?.id} className='p-3 md:basis-1/2 lg:basis-1/3'>
              <JournalComponent journal={journal}/>
            </div>
          ))}

          <div className='w-full flex flex-row justify-center'>
            {journals?.length % 30 === 0 ? (
              <Button onClick={() => loadMore()}>Load More</Button>
            ) : (
              <></>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default JournalsPage;
