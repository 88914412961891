import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, Link as A } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';
import {http, axios} from '../../Service/http';

import {Card, CardHeader, CardBody, CardFooter} from "@nextui-org/react";
import {Tabs, Tab} from "@nextui-org/react";
import { Image, Divider, Button, Link } from "@nextui-org/react";
import {Avatar, AvatarGroup, Textarea} from "@nextui-org/react";
import {Chip, Input} from "@nextui-org/react";

// Component
import ProfilePostsComponent from '../../Components/Profile/ProfilePostsComponent';
import ProfileJournalsComponent from '../../Components/Profile/ProfileJournalsComponent';

function ProfilePage() {
  const { AtHandle } = useParams();
  
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [handle, setHandle] = useState('');
  const [isMe, setIsMe] = useState(false);
  const [profile, setProfile] = useState<any>(null);
  const [profileTags, setProfileTags] = useState<any>([]);
  const [tags, setTags] = useState<any>([]);
  const [displayTags, setDisplayTags] = useState<any>([]);
  const [tagSearch, setTagSearch] = useState<any>("");

  const [editMode, setEditMode] = useState(false);
  const [updateProfileName, setUpdateProfileName] = useState("");
  const [updateProfileAge, setUpdateProfileAge] = useState("");
  const [updateProfileGender, setUpdateProfileGender] = useState("");
  const [updateProfileOrientation, setUpdateProfileOrientation] = useState("");
  const [updateProfileBio, setUpdateProfileBio] = useState("");

  useEffect(() => {
    if(AtHandle){
      const handleText = AtHandle?.split('@')[1];
      setHandle(handleText);

      if(handleText === AppContextState?.profile?.handle){
        setIsMe(true);
      } else {
        setIsMe(false);
      }
  
      http.get(`/profile/${handleText}`).then((res) => {
        setProfile(res.data.profile);
        setProfileTags(res.data.profile?.tags);
      });
    }
	}, [AtHandle, AppContextState.profile]);

  useEffect(() => {
    http.get(`/tag`).then((res) => {
      setTags(res.data.tags);
    });
  }, [profileTags]);

  const removeTag = (tag: any) => {
    http.delete(`/profile/${handle}/tag/${tag?.id}`).then((res) => {
      setProfileTags(profileTags.filter((loopedTag: any) => {
        if(loopedTag.id !== tag.id){
          return tag;
        }
        return false;
      }));
      searchTags();
    });
  }

  const addTag = (tag: any) => {
    http.post(`/profile/${handle}/tag/${tag?.id}`).then((res) => {
      setProfileTags([tag, ...profileTags]);
      searchTags();
    });
  }

  const searchTags = (text: string = '') => {
    setTagSearch(text);
    const profileTagIds: Array<any> = profileTags?.map((tag: any) => tag.id);
    setDisplayTags(tags.filter((tag: any) => {
      if(tag && profileTagIds){
        if(tag?.name.includes(text) && !profileTagIds.includes(tag.id)){
          return tag;
        }
      }
      return false;
    }))
  }

  const toggleEditMode = () => {
    setUpdateProfileName(profile.name);
    setUpdateProfileAge(profile.age);
    setUpdateProfileGender(profile.gender);
    setUpdateProfileOrientation(profile.orientation);
    setUpdateProfileBio(profile.bio);

    setEditMode(true);
  }

  const updateProfile = () => {
    http.patch(`/profile/${handle}`, {
      name: updateProfileName,
      age: updateProfileAge,
      gender: updateProfileGender,
      orientation: updateProfileOrientation,
      bio: updateProfileBio,
    }).then((res) => {
      const newProfileObj = profile;
      newProfileObj.name = updateProfileName;
      newProfileObj.age = updateProfileAge;
      newProfileObj.gender = updateProfileGender;
      newProfileObj.orientation = updateProfileOrientation;
      newProfileObj.bio = updateProfileBio;
      setEditMode(false);
    });
  }

  return (
    <div className="h-[calc(100vh-64px-64px)] py-5 flex flex-col md:flex-row gap-3">

      {/* Profile card */}
      <Card className="h-full basis-full sm:basis-1/2 lg:basis-1/3">
        <CardHeader className="flex flex-col gap-3">
          {/* Name and handle */}
          <div className='w-full flex justify-between'>
            <div className="flex flex-col">
              {editMode ? (
                <Input size='sm' type="text" placeholder='Update Name' value={updateProfileName} onChange={(event) => {
                  setUpdateProfileName(event.target.value);
                }}/>
              ) : (
                <p className="text-md">{profile?.name}</p>
              )}
              <p className="text-small text-default-500">@{profile?.handle}</p>
            </div>

            {/* Follow find */}
            <div className="flex justify-center items-center gap-2">
              {!isMe ? (
                <>
                  <Button color="primary" size="sm">Follow</Button>
                  <Button color="primary" size="sm">Friend</Button>
                </>
              ) : (
                <>
                  {editMode ? (
                    <>
                      <Button color="danger" size="sm" onClick={() => setEditMode(false)}>Cancel</Button>
                      <Button color="primary" size="sm" onClick={() => updateProfile()}>Save</Button>
                    </>
                  ) : (
                    <Button color="primary" size="sm" onClick={() => toggleEditMode()}>Edit</Button>
                  )}
                </>
              )}
            </div>
          </div>

          {/* Age gender orientation */}
          {editMode ? (
            <div className='w-full flex flex-col gap-2'>
              <Input size='sm' type="number" placeholder='Update Age' value={updateProfileAge} onChange={(event) => {
                  setUpdateProfileAge(event.target.value);
                }}/>
              <Input size='sm' type="text" placeholder='Update Gender' value={updateProfileGender} onChange={(event) => {
                  setUpdateProfileGender(event.target.value);
                }}/>
              <Input size='sm' type="text" placeholder='Update Orientation' value={updateProfileOrientation} onChange={(event) => {
                  setUpdateProfileOrientation(event.target.value);
                }}/>
            </div>
          ) : (
            <div className='w-full flex flex-row justify-around'>
              <p className="text-small text-default-500">Age: {profile?.age}</p>
              <p className="text-small text-default-500">Gender: {profile?.gender}</p>
              <p className="text-small text-default-500">Orientation: {profile?.orientation}</p>
            </div>
          )}
        </CardHeader>

        <Divider/>

        {/* Main profile */}
        <CardBody className='flex flex-col gap-3'>

          {/* Profile image */}
          {profile?.image ? (
            <Image
              alt="Profile image"
              src={`${process.env.REACT_APP_API}${profile?.image}`}
              onClick={() => navigate(`/profile/@${handle}/images`)}
              className='hover:cursor-pointer'
            />
          ) : (
            <Image
              alt="Profile image"
              src={`${process.env.REACT_APP_API_BASE}/storage/tempProfile.png`}
              onClick={() => navigate(`/profile/@${handle}/images`)}
              className='hover:cursor-pointer'
            />
          )}

          {/* Friends following followers */}
          <div className='flex flex-row justify-around text-sm'>
            <Link className='hover:cursor-pointer' onClick={() => navigate(`/profile/${AtHandle}/friends`)}>Friends ({profile?.friends_count})</Link>
            <Link className='hover:cursor-pointer' onClick={() => navigate(`/profile/${AtHandle}/followers`)}>Followers ({profile?.followers_count})</Link>
            <Link className='hover:cursor-pointer' onClick={() => navigate(`/profile/${AtHandle}/following`)}>Following ({profile?.following_count})</Link>
          </div>

          <Divider/>

          {/* Bio */}
          <div>
            {editMode ? (
              <Textarea
                placeholder="Update Bio"
                value={updateProfileBio}
                onChange={(event) => {
                  setUpdateProfileBio(event.target.value);
                }}
              />
            ) : (
              <p className=' rounded-xl p-1'>{profile?.bio}</p>
            )}
          </div>
          
          <Divider/>

          {/* Profile tags */}
          <div className='flex flex-col gap-2'>

            {isMe ? (
              <>
              <Input size='sm' type="text" placeholder='Tag search' value={tagSearch} onChange={(event) => {
                searchTags(event.target.value);
              }}/>

              {tagSearch !== "" ? (
                <div className='flex flex-col gap-2'>

                <div className='flex flex-row flex-wrap gap-2'>
                  {displayTags?.map((tag: any, index: number) => (
                    <div key={tag?.id}>
                      <Chip className='hover:cursor-pointer' onClick={() => addTag(tag)}>{tag?.name}</Chip>
                    </div>
                  ))}
                </div>

                <Divider/>

              </div>
              ) : (
                <></>
              )}
            </>
            ) : (
              <></>
            )}

            <div className='flex flex-row flex-wrap gap-2'>
              {profileTags?.map((tag: any, index: number) => (
                <div key={tag?.id}>
                  {isMe ? (
                    <Chip onClose={() => removeTag(tag)}>{tag?.name}</Chip>
                  ) : (
                    <Chip>{tag?.name}</Chip>
                  )}
                </div>
              ))}
            </div>
          </div>
        </CardBody>
      </Card>

      {/* Profile content (post journal roleplay) */}
      <Card className="h-full basis-full sm:basis-1/2 lg:basis-2/3">
        <CardBody className='p-3'>
          <Tabs aria-label="Options" fullWidth>

            {/* Posts tab */}
            <Tab 
              key="posts" 
              title={`Posts (${profile?.posts_count})`}
            >
              { profile && <ProfilePostsComponent profile={profile}/> }
            </Tab>

            {/* Journals tab */}
            <Tab key="journals" title={`Journals (${profile?.journals_count})`}>
              { profile && <ProfileJournalsComponent profile={profile}/> }
            </Tab>

            {/* Roleplay tabs */}
            <Tab key="roleplays" title={`Roleplays (${profile?.roleplays_count})`}>
              <Card>
                <CardBody><p>Roleplays</p></CardBody>
              </Card> 
            </Tab>

            {/* Likes tabs */}
            <Tab key="likes" title={`Likes (${profile?.likes_count})`}>
              <Card>
                <CardBody><p>Likes</p></CardBody>
              </Card> 
            </Tab>

            {/* Comments tabs */}
            <Tab key="comments" title={`Comments (${profile?.comments_count})`}>
              <Card>
                <CardBody><p>Comments</p></CardBody>
              </Card> 
            </Tab>

          </Tabs>
        </CardBody>
      </Card>

    </div>
  );
}

export default ProfilePage;
