import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';

import { useNavigate, Link } from 'react-router-dom';
import { AppContext } from './Context/AppContext';

// Components
import Router from './Router';
import HeaderComponent from './Components/HeaderComponent';
import FooterComponent from './Components/FooterComponent';

function App() {

  const navigate = useNavigate();

  const [accessToken, setAccessToken]: any = useState(null);
  const [user, setUser]: any = useState(null);
  const [profiles, setProfiles]: any = useState(null);
  const [profile, setProfile]: any = useState(null);

  useEffect(() => {
    if(localStorage.getItem('access_token')){
      setAccessToken(JSON.parse(localStorage.getItem('access_token') || '{}'));
    }

    if(localStorage.getItem('user')){
      setUser(JSON.parse(localStorage.getItem('user') || '{}'));
    }

    if(localStorage.getItem('profiles')){
      const profiles = JSON.parse(localStorage.getItem('profiles') || '{}');
      setProfiles(profiles);
    }

    if(localStorage.getItem('profile')){
      const profile = JSON.parse(localStorage.getItem('profile') || '{}');
      setProfile(profile);
    }

    document.addEventListener('response_401', (event) => {
      logout();
    });
  }, []);

  const updateAccessToken = (newAccessToken: string | null) => {
    if(newAccessToken){
      setAccessToken(newAccessToken);
      localStorage.setItem('access_token', JSON.stringify(newAccessToken));
    } else {
      setAccessToken(null);
      localStorage.removeItem('access_token');
    }
  }

  const updateUser = (newUser: any | null) => {
    if(newUser){
      setAccessToken(newUser);
      localStorage.setItem('user', JSON.stringify(newUser));
    } else {
      setAccessToken(null);
      localStorage.removeItem('user');
    }
  }

  const updateProfiles = (newProfiles: Array<any> | null) => {
    if(newProfiles){
      setAccessToken(newProfiles);
      localStorage.setItem('profiles', JSON.stringify(newProfiles));
    } else {
      setAccessToken(null);
      localStorage.removeItem('profiles');
    }
  }

  const updateProfile = (newProfile: any | null) => {
    if(newProfile){
      setAccessToken(newProfile);
      localStorage.setItem('profile', JSON.stringify(newProfile));
    } else {
      setAccessToken(null);
      localStorage.removeItem('profile');
    }
  }

  const logout = () => {
    updateAccessToken(null);
    updateUser(null);
    updateProfiles(null);
    updateProfile(null);

    navigate('/login');
  }

  return (
    <div className="dark min-h-screen">
      <AppContext.Provider
				value={{
          accessToken,
          updateAccessToken,
          user,
          updateUser,
          profiles,
          updateProfiles,
          profile,
          updateProfile,
        }}
			>
        <header className='h-16 bg-slate-950'>
          <HeaderComponent/>
        </header>

        <main className='min-h-[calc(100vh-64px-64px)] bg-white dark:bg-slate-900 text-black dark:text-white'>
          <div className='max-w-5xl min-h-[calc(100vh-64px-64px)] mx-auto'>
            <Router/>
          </div>
        </main>

        <footer className='h-16 bg-slate-950'>
          <FooterComponent/>
        </footer>
      </AppContext.Provider>
    </div>
  );
}

export default App;
