import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A, useParams } from 'react-router-dom';

import { AppContext } from '../Context/AppContext';
import {http, axios} from '../Service/http';

import {Button, Input, Image} from "@nextui-org/react";

function ImageUploadComponent({images, setImages}: any) {
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);
  const reader = new FileReader();

  const [files, setFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState<any>([]);

  const uploadImages = (files: any) => {
    setFiles(Array.from(files));
    // files.forEach((file: any, index: number) => {
    //   setFilePreviews([reader.readAsDataURL(file), ...filePreviews]);
    // });
  }

  const saveImages = () => {
    const formData = new FormData();
    files.forEach((file: any, index: any) => {
      formData.append(`images[${index}]`, file);
    });

    http.post(`/image/bulk`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      setImages([...res.data.images, ...images]);
      setFiles([]);
    });
  }

  return (
    <>
    <div className='flex flex-row gap-2'>
      <Input multiple type='file' onChange={(event: any) => {
        uploadImages(event.target.files);
      }}/>
      <Button onClick={() => {
        saveImages()
      }}>Upload</Button>
    </div>

    {/* <div>
      {filePreviews.map((file: any, index) => (
        <div key={index}>
          <Image
            width={300}
            src={reader.readAsDataURL(file) || ""}
          />
        </div>
      ))}
    </div> */}
      
    </>
  );
}

export default ImageUploadComponent;
