import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A } from 'react-router-dom';

import { AppContext } from '../Context/AppContext';
import {http, axios} from '../Service/http';

import {Card, CardHeader, CardBody, CardFooter} from "@nextui-org/react";
import {Tabs, Tab} from "@nextui-org/react";
import { Image, Divider, Link, Button, Input, User, Tooltip } from "@nextui-org/react";
import {Avatar, AvatarGroup, AvatarIcon} from "@nextui-org/react";

import { HeartIcon  } from '@heroicons/react/24/solid'

function CommentComponent({comment}: any) {
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [isLiked, setIsLiked] = useState<boolean>();
  const [likeCount, setLikeCount] = useState<any>();
  const [commentCount, setCommentCount] = useState(0);
  const [openComments, setOpenComments] = useState(false);
  const [openReply, setOpenReply] = useState(false);
  const [comments, setComments] = useState<any>([]);
  const [newCommentText, setNewCommentText] = useState('');

  useEffect(() => {
    // console.log(comment);
    setIsLiked(comment?.is_liked);
    setLikeCount(comment?.likes_count);
    setCommentCount(comment?.comments_count);
  }, [comment]);

  const toggleLike = () => {
    if(isLiked){
      http.delete(`/comment/${comment?.id}/like`).then((res) => {
        setIsLiked(false);
        setLikeCount(likeCount - 1);
      });
    } else {
      http.post(`/comment/${comment?.id}/like`).then((res) => {
        setIsLiked(true);
        setLikeCount(likeCount + 1);
      });
    }
  }

  const toggleComments = () => {
    if(!openComments && commentCount > 0){
      http.get(`/comment/${comment?.id}`).then((res) => {
        setComments(res.data.comment.comments);
        setOpenComments(true);
      });
    } else {
      setComments([]);
      setOpenComments(false);
    }
  }

  const toggleReply = () => {
    setOpenReply(!openReply);
  }

  const newComment = () => {
    http.post(`/comment/${comment.id}`, {
      text: newCommentText
    }).then((res) => {
      res.data.comment.is_liked = false;
      res.data.comment.likes_count = 0;
      res.data.comment.comments_count = 0;
      res.data.comment.profile = AppContextState?.profile;

      setCommentCount(commentCount + 1);
      setComments([res.data.comment, ...comments]);
      setNewCommentText('');
      setOpenReply(false);
    });
  }

  return (
    <>
      <div key={comment?.id} className='p-2 flex flex-row gap-2'>
        <Tooltip className='text-xs' placement="bottom" content={`@${comment?.profile?.handle}`}>

          {comment?.profile?.image ? (
            <Avatar src={`${process.env.REACT_APP_API}${comment?.profile?.image}`} className='hover:cursor-pointer' onClick={() => navigate(`/profile/@${comment?.profile?.handle}`)} />
          ) : (
            <Avatar src={`${process.env.REACT_APP_API_BASE}/storage/tempProfile.png`} className='hover:cursor-pointer' onClick={() => navigate(`/profile/@${comment?.profile?.handle}`)} />
          )}
        </Tooltip>

        <div className='w-full'>
          <Card className='w-fit px-5 hover:cursor-pointer' onDoubleClick={() => toggleLike()}>
            <CardBody className='p-2'>
              <p>{comment?.text}</p>
            </CardBody>
          </Card>

          <div className='flex flex-col'>

            {/* Comment functions */}
            <div className='flex flex-row gap-2'>
              {isLiked ? (
                <Link className='text-sm hover:cursor-pointer text-rose-500' onClick={() => toggleLike()}>Unlike ({likeCount})</Link>
              ) : (
                <Link className='text-sm hover:cursor-pointer' onClick={() => toggleLike()}>Like ({likeCount})</Link>
              )}
              <Link className='text-sm hover:cursor-pointer' onClick={() => toggleReply()}>Reply</Link>
              <Link className='text-sm hover:cursor-pointer' onClick={() => toggleComments()}>Comments ({commentCount})</Link>
            </div>

            {/* new comment */}
            {openReply ? (
              <div className='flex flex-row'>
                <Input size='sm' type="text" placeholder='New comment' value={newCommentText} onChange={(event) => {
                  setNewCommentText(event.target.value);
                }}/>
                <Button color="primary" size="sm" onClick={() => {newComment()}}>Post</Button>
              </div>
            ) : (
              <></>
            )}
          </div>

          {/* Child Comments */}
          <div>
            {comments?.map((comment: any, index: number) => (
              <div key={comment?.id || index}>
                <CommentComponent comment={comment}/>
              </div>
            ))}
          </div>

        </div>
        
      </div>
    </>
  );
}

export default CommentComponent;
