import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';
import {http, axios} from '../../Service/http';

function RoleplaysPage() {
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  return (
    <>
      <p>Roleplays</p>
    </>
  );
}

export default RoleplaysPage;
