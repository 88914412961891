import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, Link as A } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';
import {http, axios} from '../../Service/http';

import {Card, CardHeader, CardBody, CardFooter} from "@nextui-org/react";
import {User, Divider, Link, Image, Button } from "@nextui-org/react";
import {Tabs, Tab} from "@nextui-org/react";
import {Avatar, AvatarGroup, Textarea} from "@nextui-org/react";
import {Chip, Input, Checkbox} from "@nextui-org/react";

import ProfileCardComponent from '../../Components/ProfileCardComponent';
import TagComponent from '../../Components/TagComponent';

function ProfilesPage() {
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [profiles, setProfiles] = useState<any>([]);

  const [hasImageSearch, setHasImageSearch] = useState(false);
  const [nameSearch, setNameSearch] = useState("");
  const [bioSearch, setBioSearch] = useState("");
  const [tagSearch, setTagSearch] = useState<any>([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    searchProfiles();
	}, []);

  const searchProfiles = () => {
    http.get(`/profile`, {
      params: {
        name: nameSearch,
        bio: bioSearch,
        tags: tagSearch?.map((tag: any) => tag.id),
        order: "latest",
        page: 1,
        quantity: 30,
        image: hasImageSearch,
      }
    }).then((res) => {
      console.log(res.data.profiles);
      setProfiles(res.data.profiles);
    });
  }

  const loadMore = () => {
    http.get(`/profile`, {
      params: {
        name: nameSearch,
        bio: bioSearch,
        tags: tagSearch?.map((tag: any) => tag.id),
        order: "latest",
        page: page + 1,
        quantity: 30
      }
    }).then((res) => {
      setProfiles([...profiles, ...res.data.profiles]);
      setPage(page + 1);
    });
  }

  return (
    <div className="min-h-[calc(100vh-64px-64px)] w-full py-5">
      <Card>
        <CardHeader className='flex flex-row gap-2 items-start'>
          {/* Search */}
          <div className='basis-1/4 flex flex-col'>
            <Input size='sm' type="text" label="Search Name" value={nameSearch} onChange={(event) => {
              setNameSearch(event.target.value);
            }}/>
            <Checkbox onChange={(event: any) => {
              setHasImageSearch(event.target.checked);
            }}>Profile Picture</Checkbox>
          </div>

          <div className='basis-1/4'>
            <Input size='sm' type="text" label="Search Bio" value={bioSearch} onChange={(event) => {
              setBioSearch(event.target.value);
            }}/>
          </div>

          <div className='basis-2/4 flex flex-row items-center'>
            <TagComponent selectedTagsInit={[]} onTagSelect={(tags: any) => {
              setTagSearch(tags);
            }}/>
            
          </div>

          <div>
            <Button color='primary' onClick={() => searchProfiles()}>Search</Button>
          </div>
        </CardHeader>

        <Divider/>

        {/* List of profiles */}
        <CardBody className='flex flex-row flex-wrap'>
          {profiles?.map((profile: any) => (
            <div key={profile?.id} className='p-3 md:basis-1/2 lg:basis-1/3'>
              <ProfileCardComponent profile={profile}/>
            </div>
          ))}

          <div className='w-full flex flex-row justify-center'>
            {profiles % 30 === 0 ? (
              <Button onClick={() => loadMore()}>Load More</Button>
            ) : (
              <></>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default ProfilesPage;

