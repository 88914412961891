import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { AppContext } from '../Context/AppContext';
import {http, axios} from '../Service/http';

function FooterComponent() {
  const navigate = useNavigate();
	const AppContextState = useContext(AppContext);

  return (
    <>
      <p>Prism Labs</p>
    </>
  );
}

export default FooterComponent;
