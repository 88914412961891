import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A, useParams } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';
import {http, axios} from '../../Service/http';

import PostComponent from '../PostComponent';
import NewPostComponent from '../NewPostComponent';

function ProfilePostsComponent({profile}: any) {
  const { AtHandle } = useParams();

  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [posts, setPosts] = useState<any>([]);
  const [handle, setHandle] = useState('');
  const [isMe, setIsMe] = useState(false);

  useEffect(() => {
    if(AtHandle){
      const handleText = AtHandle?.split('@')[1];
      setHandle(handleText);

      if(handleText === AppContextState?.profile?.handle){
        setIsMe(true);
      } else {
        setIsMe(false);
      }

      http.get(`/post`, {
        params: {
          profile_id: profile?.id,
          order: "latest"
        }
      }).then((res) => {
        setPosts(res.data.posts);
      });
    }
  }, [profile, AtHandle]);

  return (
    <>
      <div className='flex flex-col gap-4 overflow-y-auto'>

        {isMe ? (
          <NewPostComponent posts={posts} setPosts={setPosts} />
        ) : (
          <></>
        )}

        {posts?.map((post: any, index: number) => (
          <div key={post.id}>
            <PostComponent post={post}/>
          </div>
        ))}
      </div>
    </>
  );
}

export default ProfilePostsComponent;
