import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { AppContext } from '../Context/AppContext';
import {http, axios} from '../Service/http';

import {Card, CardHeader, CardBody, CardFooter, Divider, Button, Input} from "@nextui-org/react";

import { EnvelopeIcon, LockClosedIcon} from '@heroicons/react/24/solid'

function LoginPage() {
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = () => {
    setIsLoading(true);
    setIsError(false);
    setErrorMessage("");

    http.post('/auth/login', {
      email: email,
      password: password
    }).then((response) => {
      setIsLoading(false);
      setEmail("");
      setPassword("");

      if(response.status === 200){
        AppContextState.updateAccessToken(response.data.access_token);
        AppContextState.updateUser(response.data.user);
        AppContextState.updateProfiles(response.data.user.profiles);
        AppContextState.updateProfile(response.data.user.profiles[0]);

        navigate("/");
      }
    }).catch((error) => {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(error.response.data.message);
      setEmail("");
      setPassword("");
    });
  }

  return (
    <div className="min-h-[calc(100vh-64px-64px)] flex justify-center items-center">
      <Card className="w-[400px] h-fit bg-slate-950 p5 m-3">
        <CardHeader className="flex gap-3">
          <div className="flex flex-col">
            <p className="text-md">Login</p>
            <p className="text-small text-default-500">Please enter your email and password to login.</p>
          </div>
        </CardHeader>
        <Divider/>
        <CardBody className='flex gap-5'>
          <Input isDisabled={isLoading} type="email" label="email" placeholder='Enter your email' value={email} endContent={
            <EnvelopeIcon className='w-5' />
          } onChange={(event) => {
            setEmail(event.target.value);
          }} />
          <Input isDisabled={isLoading} type="password" label="password" placeholder='Enter your password' value={password}endContent={
            <LockClosedIcon className='w-5' />
          } onChange={(event) => {
            setPassword(event.target.value);
          }} />
          {isError && (<p className='text-rose-700'>{errorMessage}</p>)}
        </CardBody>
        <Divider/>
        <CardFooter>
          <Button className='w-fit' color='primary' onClick={() => login()} isLoading={isLoading}>Log In</Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default LoginPage;
