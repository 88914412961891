import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';
import {http, axios} from '../../Service/http';

import {Card, CardHeader, CardBody, CardFooter, Divider, Input, Button} from "@nextui-org/react";

import PostComponent from '../../Components/PostComponent';
import TagComponent from '../../Components/TagComponent';

function PostsPage() {
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [posts, setPosts] = useState<any>([]);

  const [textSearch, setTextSearch] = useState<any>("");
  const [tagSearch, setTagSearch] = useState<any>([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    searchPosts();
	}, []);

  const searchPosts = () => {
    http.get(`/post`, {
      params: {
        text: textSearch,
        tags: tagSearch?.map((tag: any) => tag.id),
        order: "latest",
        page: 1,
        quantity: 30,
      }
    }).then((res) => {
      setPosts(res.data.posts);
    });
  }

  const loadMore = () => {
    http.get(`/post`, {
      params: {
        text: textSearch,
        tags: tagSearch?.map((tag: any) => tag.id),
        order: "latest",
        page: page + 1,
        quantity: 30,
      }
    }).then((res) => {
      setPosts([...posts, ...res.data.posts]);
      setPage(page + 1);
    });
  }

  return (
    <div className="min-h-[calc(100vh-64px-64px)] w-full py-5">
      <Card>
        <CardHeader className='flex flex-row gap-2 items-start'>
          <div className='basis-1/4 flex flex-col'>
            <Input size='sm' type="text" label="Search Text" value={textSearch} onChange={(event) => {
              setTextSearch(event.target.value);
            }}/>
          </div>
          <div className='basis-2/4 flex flex-col'>
            <TagComponent selectedTagsInit={[]} onTagSelect={(tags: any) => {
              setTagSearch(tags);
            }}/>
          </div>
          {/* <div className='basis-1/4 flex flex-col'>
          </div> */}
          <div className='basis-1/4 flex flex-col'>
            <Button color='primary' onClick={() => searchPosts()}>Search</Button>
          </div>
        </CardHeader>

        <Divider/>

        {/* List of profiles */}
        <CardBody className='flex flex-row flex-wrap'>
          {posts?.map((post: any) => (
            <div key={post?.id} className='p-3 md:basis-1/2 lg:basis-1/3'>
              <PostComponent post={post}/>
            </div>
          ))}

          <div className='w-full flex flex-row justify-center'>
            {posts.length % 30 === 0 ? (
              <Button onClick={() => loadMore()}>Load More</Button>
            ) : (
              <></>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default PostsPage;
