import axios from 'axios';

import { useContext } from 'react';
import { AppContext } from '../Context/AppContext';

let axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API,
	headers: {
		'Content-Type': 'application/json',
	},
});

axiosInstance.interceptors.request.use((request) => {
  let accessToken = localStorage.getItem("access_token");
	if (accessToken && request.headers) {
		request.headers['Authorization'] = `Bearer ${JSON.parse(accessToken)}`;
	}

  let profile = localStorage.getItem("profile");
  if (profile && request.headers) {
		request.headers['X-Profile'] = JSON.parse(profile).id;
	}

	return request;
});

axiosInstance.interceptors.response.use((response) => { 
  return response;
}, (error) => {
  switch(error.response.status) {
    case 401:
      document.dispatchEvent(new CustomEvent('response_401', {
        detail: { message: error.response.data.message },
      }));
      break;
  }
  return error.response;
});

export { axiosInstance as http };
export { axios };
