import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as A, useParams } from 'react-router-dom';

import { AppContext } from '../../Context/AppContext';
import {http, axios} from '../../Service/http';

import JournalComponent from '../JournalComponent';
import { Button } from '@nextui-org/react';

function ProfileJournalsComponent({profile}: any) {
  const { AtHandle } = useParams();

  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  const [journals, setJournals] = useState<any>([]);
  const [handle, setHandle] = useState('');
  const [isMe, setIsMe] = useState(false);

  useEffect(() => {
    if(AtHandle){
      const handleText = AtHandle?.split('@')[1];
      setHandle(handleText);

      if(handleText === AppContextState?.profile?.handle){
        setIsMe(true);
      } else {
        setIsMe(false);
      }

      http.get(`/journal`, {
        params: {
          profile_id: profile?.id,
          order: 'latest'
        }
      }).then((res) => {
        setJournals(res.data.journals);
      });
    }
	}, [profile]);

  return (
    <div className="flex flex-col gap-2">
      {isMe ? (
        <Button onClick={() => navigate(`/journal/create`)}>New Journal</Button>
      ) : (
        <></>
      )}

      {journals?.map((journal: any, index: number) => (
        <div key={journal.id}>
          <JournalComponent journal={journal}/>
        </div>
      ))}
    </div>
  );
}

export default ProfileJournalsComponent;
