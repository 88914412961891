import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../Context/AppContext';
import {http, axios} from '../Service/http';

// Next UI
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Link, Button} from "@nextui-org/react";
import {User, Divider, Avatar } from "@nextui-org/react";
import {Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, DropdownSection} from "@nextui-org/react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure} from "@nextui-org/react";
import {Textarea, Input} from "@nextui-org/react";

// Icons
import { BellIcon, BellAlertIcon } from '@heroicons/react/24/solid'

function ProfileManagementComponent() {
  const navigate = useNavigate();
	const AppContextState: any = useContext(AppContext);

  // State
  const [notifications, setNotifications] = useState<any>([]);

  useEffect(() => {
    http.get(`/notification`).then((res) => {
      setNotifications(res.data.notifications);
    });
  }, []);

  return (
    <>
      <NavbarItem className='flex justify-center items-center gap-4'>
        <Dropdown className='text-white bg-slate-950'>
          <DropdownTrigger>
            <Button isIconOnly>
              {notifications.length > 0 ? (
                <BellAlertIcon className='w-5'/>
              ) : (
                <BellIcon className='w-5'/>
              ) }
              
            </Button>
          </DropdownTrigger>
          <DropdownMenu aria-label="Static Actions" className='text-white bg-slate-950'>

            <DropdownSection>
              {notifications.map((notification: any, index: number) => (
                <DropdownItem className='text-primary hover:cursor-pointer' color="primary">
                  {notification.message}
                </DropdownItem>
              ))}
            </DropdownSection>

            <DropdownSection>
              <DropdownItem key="create" className='text-danger hover:cursor-pointer' color="primary">
                Clear
              </DropdownItem>
            </DropdownSection>

          </DropdownMenu>
        </Dropdown>
      </NavbarItem>
    </>
  );
}

export default ProfileManagementComponent;
